import React from 'react'
import Header from "./Header"
import Menu from "./Menu"
import {UserContext} from "../contexts/UserContext"
import LoginForm from "./Login"
import NavBar from "./NavBar"

export default function MainContainer() {
    const {user, authReady} = React.useContext(UserContext)
    return (
        <div>
            {authReady &&
            <div>
                {user
                    ? <div>
                        <NavBar/>
                        <Header/>
                        <Menu/>
                    </div>
                    : <LoginForm/>
                }
            </div>
            }
        </div>
    )
}
