const ACCESS_KEY = "imasesAccess"
const REFRESH_KEY = "imasesRefresh"

export const API_TOKEN_REFRESH_URL = "/api/token/refresh/",
    API_TOKEN_VERIFY_URL = "/api/token/verify/",
    API_TOKEN_URL = "/api/token/",
    API_USER_URL = "/api/v1/me/",
    API_USER_FILES_URL = "/api/v1/files/"


async function getData(url, limiter) {
    limiter = limiter || 1
    if (limiter > 2) {
        // django rest framework simplejwt passed jwt verification for dead accounts and responds with HTTP 403
        // for invalid tokens and invalid users. limiter needed to avoid eternal loop for inactive users
        await removeTokens()
    }
    let headers = {Authorization: `Bearer ${localStorage.getItem(ACCESS_KEY)}`}
    let res = await fetch(url, {
        method: "GET",
        headers: headers
    })
    if (res.status === 200 || res.status === 401) {
        return res
    }
    if (res.status === 403) {
        const status = await refreshToken()
        if (status.status) {
            return await getData(url, limiter + 1)
        } else {
            return res
        }
    }
}

async function postData(url, data, limiter) {
    limiter = limiter || 1
    if (limiter > 2) return
    const headers = {"Content-Type": "application/json"}
    const res = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
    })
    if (res.status === 200 || res.status === 401) return res
    if (res.status === 403) {
        const status = await refreshToken()
        if (status.status) {
            return postData(url, data, limiter + 1)
        } else {
            return res
        }
    }
}

export function getHeaders() {
    return {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_KEY)}`
    }
}

export async function getTokens() {
    const access = await localStorage.getItem(ACCESS_KEY)
    const refresh = await localStorage.getItem(REFRESH_KEY)

    if (refresh && !await verifyToken(refresh)) return removeTokens()

    return {
        status: Boolean(access && refresh),
        access: access,
        refresh: refresh
    }
}

export function setTokens(json) {
    const access = json["access"] || localStorage.getItem(ACCESS_KEY)
    const refresh = json["refresh"] || localStorage.getItem(REFRESH_KEY)
    localStorage.setItem(ACCESS_KEY, access)
    localStorage.setItem(REFRESH_KEY, refresh)
    return {
        status: Boolean(access && refresh),
        access: access,
        refresh: refresh
    }
}

export async function removeTokens() {
    const access = await localStorage.removeItem(ACCESS_KEY)
    const refresh = await localStorage.removeItem(REFRESH_KEY)
    return {
        status: Boolean(access && refresh)
    }
}

function getHostname() {
    return process.env.NODE_ENV === "development" ? window.location.hostname : process.env.REACT_APP_ADDIT_API_HOSTNAME
}

function getPort() {
    const hostname = getHostname()
    return (hostname === 'localhost' || hostname === '127.0.0.1' || hostname === "0.0.0.0") ? ':8000' : ''
}

export async function verifyToken(token) {
    token = token || localStorage.getItem(ACCESS_KEY)
    const url = getRootURL() + API_TOKEN_VERIFY_URL
    const data = {token: token}
    const res = await postData(url, data)
    return res.status === 200
}

export async function refreshToken(token) {
    token = token || await localStorage.getItem(REFRESH_KEY)
    if (!token) {
        return removeTokens()
    }
    const url = getRootURL() + API_TOKEN_REFRESH_URL
    const data = {refresh: token}
    let res = await postData(url, data)
    if (res.status === 200) {
        setTokens(await res.json())
        return res
    } else {
        await removeTokens()
        return res
    }
}

export async function getUser() {
    const url = getRootURL() + API_USER_URL
    let res = await getData(url)
    if (res && res.status === 200) {
        const user = await res.json()
        return user[0]
    }
}

export function getRootURL() {
    return window.location.protocol + "//" + getHostname() + getPort()
}

export function getFilesURL() {
    return getRootURL() + API_USER_FILES_URL
}

export async function getFiles() {
    const url = getFilesURL()
    let res = await getData(url)
    if (res && res.status === 200) return await res.json()
}

export async function login(data) {
    let url = "//" + getHostname() + getPort() + API_TOKEN_URL
    return await postData(url, data)
}

export function parseJwt(token) {
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(jsonPayload)
}
