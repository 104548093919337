import React from "react"
import {UserProvider} from "../contexts/UserContext"
import {ConfigProvider} from "../contexts/ConfigContext"

export default function ContextContainer(props) {
    return (
        <UserProvider>
            <ConfigProvider>
                {props.children}
            </ConfigProvider>
        </UserProvider>
    )
}
