import React, {useState, useEffect, useDebugValue} from "react"
import {Icon, Input, Loader} from 'semantic-ui-react'
import {getRootURL, API_USER_FILES_URL} from "../../services/utils"
import {ConfigConsumer} from "../../contexts/ConfigContext"
import List from './List'

function useStateWithLabel(initialValue, name) {
    const [value, setValue] = useState(initialValue)
    useDebugValue(`${name}: ${value}`)
    return [value, setValue]
}

export default function FileList() {

    const [loading, setLoading] = useStateWithLabel(true, "loading")
    const [files, setFiles] = useState(null)
    const [filterText, setFilterText] = useState('')

    const handleDelete = (value) => {
        if (!window.confirm(`Are you sure you want to delete ${value.basename}`)) return
        const {served_url} = value
        let arr = served_url.split(/\/([a-f0-9]{8})\/(.*)/).filter(item => item)
        const body = JSON.stringify({hash: arr[0], filename: arr[1]})
        const url = getRootURL() + API_USER_FILES_URL
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("imasesAccess")}`
        }
        fetch(url, {
            method: "DELETE",
            headers,
            body
        })
            .then((res) => res.json())
            .then((json) => {
                console.log(json)
                setLoading(true)
            })
    }

    function getFiles() {
        const url = getRootURL() + API_USER_FILES_URL
        const headers = {Authorization: `Bearer ${localStorage.getItem("imasesAccess")}`}
        fetch(url, {
            method: "GET",
            headers
        })
            .then((res) => res.json())
            .then((json) => {
                setFiles(json)
            })
    }

    useEffect(() => {
        if (loading) getFiles()
        setLoading(false)
        // eslint-disable-next-line
    }, [loading])

    return (
        <ConfigConsumer>
            {({windowIsWide}) =>
                <>
                    {loading && <Loader active={loading} inline="centered"/>}
                    <Input
                        icon={<Icon name='search' inverted circular link color="blue"/>}
                        placeholder='Search...'
                        onChange={(e) => setFilterText(e.target.value)}
                        fluid={!windowIsWide()}
                    />
                    <List files={files} filterText={filterText} handleDelete={handleDelete}/>
                </>
            }
        </ConfigConsumer>
    )
}
