/*
    These two functions are pulled from react-dropzone-loader source code.
    They are needed to make the customized Preview component work
 */

export const formatBytes = (b) => {
    const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let l = 0
    let n = b

    while (n >= 1024) {
        n /= 1024
        l += 1
    }

    return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`
}

export const formatDuration = (seconds) => {
    const date = new Date(0)
    date.setSeconds(seconds)
    const dateString = date.toISOString().slice(11, 19)
    if (seconds < 3600) return dateString.slice(3)
    return dateString
}
