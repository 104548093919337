import React from "react"
import {Grid, Input, Loader, Popup, Progress, Segment} from "semantic-ui-react"
import {getRootURL} from "../../services/utils"
import {formatBytes, formatDuration} from "./utils"
// import cancelImg from './assets/cancel.svg'
// import removeImg from './assets/remove.svg'
// import restartImg from './assets/restart.svg'

const iconByFn = {
    // cancel: { backgroundImage: `url(${cancelImg})` },
    // remove: { backgroundImage: `url(${removeImg})` },
    // restart: { backgroundImage: `url(${restartImg})` },
}


export default class Preview extends React.Component {

    state = {
        popupOpen: false
    }

    handleOpen = () => {
        this.setState({isOpen: true})

        this.timeout = setTimeout(() => {
            this.setState({isOpen: false})
        }, 2000)
    }

    handleClose = () => {
        this.setState({isOpen: false})
        clearTimeout(this.timeout)
    }

    responseFromMetaID = (id) => {
        let ret_arr = this.props.files.filter(file => (file.meta.id === id))
        if (ret_arr.length === 0) return
        if (ret_arr[0].xhr && ret_arr[0].xhr.responseText) return ret_arr[0].xhr.responseText
    }

    downloadUrl = (res) => {
        if (res) {
            const json = JSON.parse(res)
            return getRootURL() + json.served_url
        }
    }

    copyCodeToClipboard = () => {
        const el = this.textArea
        el.select()
        document.execCommand("copy")
    }

    render() {

        const {
            className,
            imageClassName,
            style,
            imageStyle,
            fileWithMeta: {cancel, remove, restart},
            meta: {name = '', percent = 0, size = 0, previewUrl, status, duration, validationError},
            isUpload,
            canCancel,
            canRemove,
            canRestart,
            extra: {minSizeBytes},
        } = this.props

        let title = `${name || '?'}`
        let sizeStr = `${formatBytes(size)}`
        if (duration) title = `${title}, ${formatDuration(duration)}`

        if (status === 'error_file_size' || status === 'error_validation') {
            return (
                <div className={className} style={style}>
                    <span className="dzu-previewFileNameError">{title}</span>
                    {status === 'error_file_size' &&
                    <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>}
                    {status === 'error_validation' && <span>{String(validationError)}</span>}
                    {canRemove && <span className="dzu-previewButton" style={iconByFn.remove} onClick={remove}/>}
                </div>
            )
        }

        if (status === 'error_upload_params' || status === 'exception_upload' || status === 'error_upload') {
            sizeStr = `${sizeStr} (upload failed)`
        }
        if (status === 'aborted') sizeStr = `${sizeStr} (cancelled)`

        const metaID = this.props.fileWithMeta.meta.id

        return (
            <Segment>
                <Grid divided stackable>
                    <Grid.Row columns={previewUrl ? 3 : 2}>
                        {previewUrl &&
                        <Grid.Column textAlign="center">
                            <img className={imageClassName} style={imageStyle} src={previewUrl} alt={title}
                                 title={title}/>
                        </Grid.Column>
                        }
                        <Grid.Column textAlign="center">
                            {title}<br/>
                            {sizeStr}
                        </Grid.Column>
                        <Grid.Column>
                            {isUpload && (
                                <Progress
                                    progress
                                    autoSuccess
                                    indicating={status === 'done' || status === 'headers_received' ? false : true}
                                    percent={status === 'done' || status === 'headers_received' ? 100 : Math.round(percent)}/>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            {this.responseFromMetaID(metaID)
                                ? (
                                    <Popup
                                        content="Copied!"
                                        position="right center"
                                        on='click'
                                        open={this.state.isOpen}
                                        onClose={this.handleClose}
                                        onOpen={this.handleOpen}
                                        trigger={
                                            <div>
                                                <Input
                                                    fluid
                                                    loading
                                                    iconPosition={undefined}
                                                    action={{
                                                        color: 'blue',
                                                        labelPosition: 'right',
                                                        icon: 'copy',
                                                        content: 'Copy',
                                                        onClick: () => this.copyCodeToClipboard()
                                                    }}
                                                    value={this.downloadUrl(this.responseFromMetaID(metaID))}
                                                    ref={(textarea) => this.textArea = textarea}
                                                    onClick={() => this.copyCodeToClipboard()}
                                                />
                                            </div>
                                        }
                                    />
                                )
                                : (<Loader active inline='centered'/>)
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }
}
