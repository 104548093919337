import React from 'react'
import {Button, Form, Grid, Image, Message, Segment} from 'semantic-ui-react'
import {UserContext} from '../../contexts/UserContext'

export default function LoginForm(props) {
    const [username, setUsername] = React.useState()
    const [password, setPassword] = React.useState()
    const {errorMessage, handleLogin} = React.useContext(UserContext)

    function handleSubmit() {
        username && password && handleLogin({username, password})
    }

    return (
        <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
            <Grid.Column style={{maxWidth: 450}}>
                <Segment textAlign="center">
                    <Image src={process.env.PUBLIC_URL + '/logo512.png'} style={{width: "80%", display: "inline"}}/>
                </Segment>

                <Form size='large'>
                    <Segment stacked>
                        <Form.Input
                            fluid
                            icon='user'
                            iconPosition='left'
                            onChange={(e) => setUsername(e.target.value)}
                            name="username"
                            autoComplete="username"
                            placeholder='Username'
                        />
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            onChange={(e) => setPassword(e.target.value)}
                            name="password"
                            autoComplete="password"
                            placeholder='Password'
                            type='password'
                        />

                        <Button color='blue' fluid size='large' onClick={handleSubmit}>
                            Login
                        </Button>
                        {errorMessage &&
                        <Message negative>
                            <Message.Header>
                                {errorMessage}
                            </Message.Header>
                        </Message>
                        }
                    </Segment>
                </Form>
            </Grid.Column>
        </Grid>
    )
}
