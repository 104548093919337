import React from 'react'
import '../../react-dropzone-uploader.css'
import Dropzone from 'react-dropzone-uploader'
import Preview from "./Preview"
import InputContent from "./InputContent"
import {getFilesURL, getHeaders} from "../../services/utils"
import Button from "./Button"
import Input from "./Input"
import {UserContext} from "../../contexts/UserContext"


export default class DropzoneComponent extends React.PureComponent {

    static contextType = UserContext

    render() {
        // specify upload params and url for your files
        const getUploadParams = ({meta}) => {
            return {
                url: getFilesURL(),
                headers: getHeaders()
            }
        }
        // called every time a file's `status` changes
        const handleChangeStatus = ({meta, file, xhr}, status) => {
            // console.log(status, meta, file)
            if (status === "done") {
                this.context.setFetchUser(true)
                console.debug("User refreshed")
            }
        }
        // receives array of files that are done uploading when submit button is clicked
        const handleSubmit = (files, allFiles) => {
            // console.log(files.map(f => f.meta))
            allFiles.forEach(f => f.remove())
        }
        return (
            <>
                <Dropzone
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    inputContent={InputContent}
                    InputComponent={Input}
                    accept="*"
                    submitButtonContent="Clear completed"
                    SubmitButtonComponent={props => <Button color="blue" {...props} extraProps={{color: "blue"}}/>}
                    PreviewComponent={Preview}
                />
            </>
        )
    }
}
