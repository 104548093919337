import React from "react"
import {Menu} from 'semantic-ui-react'
import {UserConsumer} from "../../contexts/UserContext"
import {SIZETABLE, fileSizeToStr} from "../../services/calc"

const NavBar = () => {
    return (
        <UserConsumer>
            {({handleLogout, user}) =>
                <Menu pointing secondary>
                    <Menu.Menu position="right">
                        <Menu.Item position="right">
                            {fileSizeToStr(user.get_uploaded_consumption)} / {user.quota} {SIZETABLE[user.quota_type + 1]} used
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position="right">
                        <Menu.Item onClick={handleLogout}>
                            {user.username} Logout
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            }
        </UserConsumer>
    )
}

export default NavBar
