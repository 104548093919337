import React from "react"
import {Accordion, Button, Divider, Grid, List as SemanticList, Segment} from 'semantic-ui-react'
import {getRootURL} from "../../services/utils"
import {dateAgo} from "../../services/calc"

export default function List(props) {
    const [activeIndex, setActiveIndex] = React.useState(null)

    function returnFiltered(row) {
        return row.basename.toLowerCase().indexOf(props.filterText.toLowerCase()) !== -1
    }

    function handleClick(e, titleProps) {
        const {index} = titleProps
        const newIndex = activeIndex === index ? -1 : index
        setActiveIndex(newIndex)
    }

    return (
        <>
            <Accordion as={SemanticList} styled fluid>
                {props.files
                    ? props.files
                        .filter((row) => {
                            return returnFiltered(row)
                        })
                        .map((value, key) => (
                            <SemanticList.Item key={key}>
                                <Accordion.Title
                                    active={activeIndex === key}
                                    index={key}
                                    content={value.basename}
                                    onClick={handleClick}
                                />
                                <Accordion.Content
                                    active={activeIndex === key}
                                >
                                    <SemanticList.Content floated="right">
                                        <Segment basic compact>
                                            <Grid columns={2} relaxed="very">
                                                <Grid.Column>
                                                    <Button size="big"
                                                            icon="cloud download" color="blue" as="a"
                                                            href={getRootURL() + value.served_url}/>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Button size="big"
                                                            onClick={() => props.handleDelete(value)}
                                                            icon="delete" color="red"/>
                                                </Grid.Column>
                                            </Grid>
                                            <Divider vertical>Or</Divider>
                                        </Segment>
                                    </SemanticList.Content>
                                    <SemanticList.Content>
                                        <SemanticList.Header>{value.file_size_str}</SemanticList.Header>
                                        <small>{dateAgo(value.created_at)}</small>
                                    </SemanticList.Content>
                                </Accordion.Content>
                            </SemanticList.Item>
                        ))
                    : ("Nothing here yet")
                }
            </Accordion>
            <h1>&nbsp;</h1>
        </>
    )

}
