import React from 'react'
import {Header as SemanticHeader, Icon} from 'semantic-ui-react'

const InputContent = () => {
    const key = new Date()
    return (
        <div key={key}>
            <SemanticHeader as='h2' icon textAlign='center' color="blue">
                <Icon name='cloud upload' circular/>
                <SemanticHeader.Content>Drag files or Click to select files</SemanticHeader.Content>
            </SemanticHeader>
        </div>
    )
}

export default InputContent
