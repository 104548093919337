import React, {createContext} from 'react'

export const ConfigContext = createContext({})

export class ConfigProvider extends React.Component {

    componentDidMount() {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions.bind(this))
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions.bind(this))
    }

    updateWindowDimensions = () => {
        this.setState({width: window.innerWidth, height: window.innerHeight})
    }

    windowIsWide = () => {
        return this.state.width >= this.state.responsiveBreakpoint
    }

    state = {
        responsiveBreakpoint: 576,
        windowIsWide: this.windowIsWide
    }

    render() {
        return (
            <ConfigContext.Provider value={this.state}>
                {this.props.children}
            </ConfigContext.Provider>
        )
    }
}

export const ConfigConsumer = ConfigContext.Consumer
