import React from "react"
import {Button as SemanticButton, Segment} from 'semantic-ui-react'

const Button = (props) => {
    const {className, buttonClassName, style, buttonStyle, disabled, content, onSubmit, files} = props

    const _disabled =
        files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
        !files.some(f => ['headers_received', 'done'].includes(f.meta.status))

    const handleSubmit = () => {
        onSubmit(files.filter(f => ['headers_received', 'done'].includes(f.meta.status)))
    }

    return (
        <Segment basic textAlign='center'>
            <div className={className} style={style}>
                <SemanticButton className={buttonClassName} style={buttonStyle}
                                {...props.extraProps}
                                onClick={handleSubmit}
                                disabled={disabled || _disabled}>
                    {content}
                </SemanticButton>
            </div>
        </Segment>
    )
}

export default Button
