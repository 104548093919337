import React, {useState} from 'react'
import {Icon, Menu as SemanticMenu} from 'semantic-ui-react'
import DropzoneComponent from "../Dropzone"
import FileList from "../FileList"

const Menu = () => {
    const [activeItem, setActiveItem] = useState("upload")
    return (
        <div>
            <SemanticMenu fluid icon='labeled'>
                <SemanticMenu.Item
                    className="tab-width"
                    name='upload'
                    active={activeItem === 'upload'}
                    onClick={() => setActiveItem("upload")}
                    color="blue"
                >
                    <Icon name='cloud upload'/>
                    Upload
                </SemanticMenu.Item>
                <SemanticMenu.Item
                    className="tab-width"
                    name='archive'
                    active={activeItem === 'archive'}
                    onClick={() => setActiveItem("archive")}
                    color="blue"
                >
                    <Icon name='archive'/>
                    Files
                </SemanticMenu.Item>
            </SemanticMenu>
            {activeItem === "upload"
                ? <DropzoneComponent/>
                : <FileList/>}
        </div>
    )
}

export default Menu
