import React, {useEffect} from 'react'
import axios from 'axios'
import {getRootURL, parseJwt, API_TOKEN_REFRESH_URL, API_USER_URL, API_TOKEN_URL} from "../services/utils"

export const UserContext = React.createContext({})

export function UserProvider(props) {
    const [user, setUser] = React.useState(null)
    const [timerHandler, setTimerHandler] = React.useState(null)
    const [shouldFetchUser, setFetchUser] = React.useState(true)
    const [authReady, setAuthReady] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(null)

    function refreshToken() {
        const url = getRootURL() + API_TOKEN_REFRESH_URL
        axios.post(url, {
            refresh: `${localStorage.getItem("imasesRefresh")}`
        })
            .then((res) => {
                doRefreshToken(res.data.access)
                localStorage.setItem('imasesAccess', res.data.access)
            })
    }

    const doRefreshToken = (accessToken) => {
        const token = parseJwt(accessToken)
        const now = Math.round(new Date().getTime() / 1000)
        let diff = (token.exp - now) * 1000
        diff -= (diff / 10)
        console.debug("doRefreshToken setting timeOut to", diff)
        setTimerHandler(setTimeout(() => refreshToken(), diff))
    }

    function getUser() {
        const accessToken = localStorage.getItem("imasesAccess")
        const headers = {"Authorization": `Bearer ${accessToken}`}
        const url = getRootURL() + API_USER_URL
        axios.get(url, {headers})
            .then((res) => {
                doRefreshToken(accessToken)
                setUser(res.data[0])
                setErrorMessage(null)
            })
            .catch((err) => {
                // console.error("catched while getUser()", err)
                if (err.response || err.response.status !== 403) return handleLogout()
                refreshToken()
                return getUser()
            })
            .then(() => setAuthReady(true))
    }

    const handleLogout = () => {
        localStorage.removeItem("imasesAccess")
        localStorage.removeItem("imasesRefresh")
        clearTimeout(timerHandler)
        setUser(null)
    }

    const handleLogin = (props) => {
        const {username, password} = props
        const url = getRootURL() + API_TOKEN_URL
        axios.post(url, {username, password})
            .then((res) => {
                localStorage.setItem('imasesAccess', res.data.access)
                localStorage.setItem('imasesRefresh', res.data.refresh)
                getUser()
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) setErrorMessage(err.response.data.detail)
                handleLogout()
            })
    }

    useEffect(() => {
        if (shouldFetchUser) getUser()
        setFetchUser(false)
        return function cleanup() {
            clearTimeout(timerHandler)
        }
        // eslint-disable-next-line
    }, [shouldFetchUser]) // Empty deps array causes side effect to be called only on mount

    const state = {
        user,
        setFetchUser,
        authReady,
        errorMessage,
        handleLogout,
        handleLogin
    }

    return (
        <UserContext.Provider value={state}>
            {props.children}
        </UserContext.Provider>
    )
}

export const UserConsumer = UserContext.Consumer
