export const SIZETABLE = ['-', 'B', 'KB', 'MB', 'GB', 'TB']

export function fileSizeToStr(fileSize) {
    for (let i = 1; i < SIZETABLE.length; i++) {
        if (fileSize < Math.pow(1024, i)) {
            return Number(fileSize / Math.pow(1024, i - 1)).toFixed(2) + " " + SIZETABLE[i]
        }
    }
}

export function dateAgo(dateString) {
    const diff = Math.round((new Date() - new Date(dateString)) / 1000)
    return dateAgoCalc(diff)
}

export function dateAgoCalc(seconds) {
    let retval = 0;
    let precision = 'seconds';

    switch (true) {
        case (seconds / 60 < 1):
            retval = seconds
            break
        case (seconds / 60 / 60 < 1):
            retval = seconds / 60
            precision = 'minutes'
            break
        case (seconds / 60 / 60 / 60 < 1):
            retval = seconds / 60 / 60
            precision = 'hours'
            break
        case (seconds / 60 / 60 / 60 / 24 < 1):
            retval = seconds / 60 / 60 / 24
            precision = 'days'
            break
        case (seconds / 60 / 60 / 60 / 24 / 7 < 1):
            retval = seconds / 60 / 60 / 24 / 7
            precision = 'weeks'
            break
        case (seconds / 60 / 60 / 60 / 24 / 7  / 4 < 1):
            retval = seconds / 60 / 60 / 24 / 7 / 4
            precision = 'months'
            break
        default:
            retval = seconds / 60 / 60 / 24 / 365
            precision = 'years'
    }
    return `${Math.floor(retval)} ${precision} ago`
}
