import React from 'react'
import {Container} from 'semantic-ui-react'

import './App.css'
import MainContainer from "./components/MainContainer"
import ContextContainer from "./components/ContextContainer"

export default function App() {
        return (
            <Container>
                <ContextContainer>
                    <MainContainer/>
                </ContextContainer>
            </Container>
        )
}
