import React from 'react'

const Input = (props) => {
    const {
        className,
        labelClassName,
        labelWithFilesClassName,
        style,
        labelStyle,
        labelWithFilesStyle,
        getFilesFromEvent,
        accept,
        multiple,
        disabled,
        content,
        withFilesContent,
        onFiles,
        files,
    } = props

    return (
        <label
            // className={files.length > 0 ? labelWithFilesClassName : labelClassName}
            className={labelClassName}
            // style={files.length > 0 ? labelWithFilesStyle : labelStyle}
            style={files.length > 0 ? {position: "inherit"} : labelStyle}
            // style={{position: "inherit"}}
        >
            {/*{files.length > 0 ? withFilesContent : content}*/}
            {content}
            <input
                className={className}
                style={style}
                type="file"
                accept={accept}
                multiple={multiple}
                disabled={disabled}
                onChange={async e => {
                    const target = e.target
                    const chosenFiles = await getFilesFromEvent(e)
                    onFiles(chosenFiles)
                    //@ts-ignore
                    target.value = null
                }}
            />
        </label>
    )
}

export default Input
