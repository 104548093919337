import React from 'react'
import {Header as SemanticHeader} from 'semantic-ui-react'

const Header = () => (
    <div>
        <SemanticHeader as='h2' icon textAlign='center' color="blue">
            <SemanticHeader.Content>Imases.org</SemanticHeader.Content>
        </SemanticHeader>
    </div>
)

export default Header
